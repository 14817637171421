/*
body {
    font-family:"Core Sans C", "Century Gothic", "AppleGothic", sans-serif;
    padding-bottom: 20px;
    color: #000000;
}
*/

/* ensure uppercase and no X in the text boxes... */
/*
input { 
    text-transform: uppercase;
}
*/

/*
N/A
input::-ms-clear {
    display: none;
}

::-webkit-input-placeholder { // WebKit browsers
text-transform: none;
body-content
}

:-moz-placeholder { // Mozilla Firefox 4 to 18
    text-transform: none;
}

::-moz-placeholder { // Mozilla Firefox 19+
    text-transform: none;
}

:-ms-input-placeholder { // Internet Explorer 10+
    text-transform: none;
}
*/

/* Set padding to keep content from hitting the edges */
/*
N/A
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}
*/

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
/*
.dl-horizontal dt {
    white-space: normal;
}
    */

/* Set width on the form input elements since they're 100% wide by default */
/*
input,
select,
textarea {
    max-width: 280px;
}
*/

/* Set padding to display errors that occur during databinding */
/*
.padding-error {
    padding-top: 20px;
}
*/
/*
@media only screen and (max-width: 767px) {
    .nofloat {
        float: none;
        padding: 10px 15px;
    }
}
*/

/*
    To Sites.css
html, body {
    height: 100%;
}
*/

/* Entire page including header/footer */
/* To LayoutStyles
#Content {
    height: 100%;
}
*/
/*
header {
    width: 100%;
}

.BodyContent {
    margin-top: 30px;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    width: 100%;
}

footer {
    display: flex;
    flex: 0 1 auto;
}
*/

.flex-Title-container {
    border: 1px solid #c3c5cd;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: flex-start;
}



.flex-container {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-flow: column nowrap;
    margin: auto;
    align-items: center;
}

.flex-innercontainer {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: row wrap;
    width: 1050px;
}



border-box {
    margin: 3px;
}

.RAPMyClaims {
    border: 1px solid #fff !important;
    position: relative !important;
}

#FooterBorder {
    background-color: #efefef;
}


.RAPQuickLinks {
    display: flex;
    min-height: 777px;
    flex-wrap: wrap;
    align-content: flex-start;
    border: none;
}
#RAPQuickLinks {
    height: 430px !important;
}

#ARMQuickSearch{
    position: relative;
    top: -5px;
}

#MessageAlerts{
    position: relative;
    top: -10px;
}

.assurancePageTitle {
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    background-color: #0f0f59;
}

    .assurancePageTitle a {
        color: #000000;
        font-size: 11px;
    }

    .assurancePageTitle .navbar {
        z-index: 1001;
        min-height: 0px;
        margin-bottom: 0px;
        background-color: transparent;
        border-color: transparent;
        border-top-width: 0px;
    }

        .assurancePageTitle .navbar .container {
            padding-right: 0px;
            padding-left: 0px;
        }

            .assurancePageTitle .navbar .container .dropdown-menu {
                width: 100%;
                min-width: 161px;
                border-radius: 0;
            }

        .assurancePageTitle .navbar .navbar-brand {
            padding: 0 0 0 0;
            margin-left: 5px;
            margin-top: 8px;
        }

    .assurancePageTitle .navbar-nav li a {
        padding-bottom: 0px;
    }

.boldFont {
    font-weight: bold;
}

.assurancePageTitle .navbar-default .navbar-nav > li > a {
    color: #ffffff;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #c3c5cd;
}

.assurancePageTitle .open > a,
.assurancePageTitle .open > a:focus,
.assurancePageTitle .open > a:hover {
    background-color: #c3c5cd !important;
}

.pageTitle {
    padding: 5px 5px 5px 0;
    width: 476px;
    border-top-left-radius: 6px;
}

.tableRow {
    display: table-row;
}

.tableCell {
    display: table-cell;
}

.assuranceTopSection2 .quickSearchControlsDiv input:-ms-input-placeholder {
    padding-left: 8px;
    color: #737d85;
    font-weight: bold;
}

.assuranceTopSection1 {
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

    .assuranceTopSection1 .title {
        float: left;
        width: 100px;
        margin-left: 33px;
        text-align: center;
        line-height: 35px;
        font-weight: bold;
        font-size: 13px;
        color: #0f0f59;
    }

.childDiv {
    width: 100%;
}

.productUpdatesDiv {
    width: 45%;
    float: left;
    text-align: right;
    margin-top: 9px;
}

.badge {
    background-color: #9ba1a9;
}

.productUpdatesDiv .badge {
    background-color: #4cc6ff;
    cursor: pointer !important;
}


.productUpdatesLink {
    line-height: 35px;
    font-size: 11px;
    text-decoration: underline;
    cursor: pointer;
    pointer-events: auto;
}

.assuranceTopSection1 .clientDisplayName {
    float: right;
    width: 100%;
    font-family: 'Optum Sans';
    text-align: right;
    line-height: 35px;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: #737d85 !important;
    pointer-events: auto;
}

    .assuranceTopSection1 .clientDisplayName a {
        cursor: pointer;
        color: #323334;
    }

.assuranceTopSection1 .clientDisplayNameDisabled a {
    float: right;
    width: 65%;
    height: 99%;
    text-align: right;
    line-height: 35px;
    font-size: 11px;
    color: black !important;
    cursor: auto;
    pointer-events: none;
}

.modal-content {
    border: 1px solid #9ba1a9;
    border-radius: 0px;
    width: 610px;
    max-height: 590px;
    font-family: 'optum-sans-regular' !important;
}

.modal-header {
    padding-left: 10px;
    border-bottom: 1px solid #c3c5cd;
    min-height: 15px;
    background-color: #efefef;
}

.modal-title {
    font-weight: bold;
    font-size: 13px !important;
    color: #0f0f59;
    font-family: 'optum-sans-regular' !important;
}

.modal-body {
    padding: 0px !important;
    font-family: 'optum-sans-regular' !important;
}

.popupScrollingList {
    overflow-y: scroll;
    overflow-y: auto;
    height: 350px;
    font-size: 12px !important;
    cursor: default !important;
    width: 100%;
}

.productUpdatesTable tr:nth-child(odd) {
    background: #f2f6f9;
}

.productUpdatesTable tr:nth-child(even) {
    background: #FFFFFF;
}

.productUpdatesTable td {
    padding-left: 0px;
}

.productUpdatesTable {
    width: 100%;
    font-family: "Core Sans C","Century Gothic","AppleGothic",sans-serif;
}

.clientListTable {
    width: 100%;
}

    .clientListTable tr:hover {
        background-color: #efefef;
    }

    .clientListTable tr :first-child {
        padding-left: 10px;
        width: 40px;
    }

    .clientListTable tr :last-child {
        padding-left: 10px;
    }

.modal-footer {
    background-color: #ebeced;
    border-top-color: #9ba1a9;
}

    .modal-footer .copyRight {
        font-size: 9pt;
        font-style: normal;
        color: #737d85;
        cursor: default;
    }

.clientLabel {
    line-height: 3em;
    padding-left: 10px;
    font-weight: bold !important;
    font-size: 13px !important;
}

.clientsearch-input {
    padding: 5px;
}

.clientFilterTextBox {
    border-bottom: 1px solid #9ba1a9;
    padding-left: 10px;
    font-size: 11px !important;
}

#clientSearchInput {
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 38px !important;
    max-height: 38px !important;
    border: none;
}

.clientFilterTextBox input:-ms-input-placeholder {
    color: #9ba1a9;
    font-size: 11px;
}

.assuranceTopSection2 {
    height: 37px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: 1px;
    background-color: #0f0f59;
    font-size: 11px;
    display: table-row;
    width: 100%;
    color: #ffffff;
    line-height: 2.1em;
}

    .assuranceTopSection2 .quickSearchControlsDiv {
        width: 730px;
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        text-align: center;
    }

        .assuranceTopSection2 .quickSearchControlsDiv input {
            height: 25px;
            margin-left: 3px;
            margin-right: 3px;
            width: 190px;
            border: 1px #737d85;
            border-radius: 3px;
            font-weight: bold;
        }

        .assuranceTopSection2 .quickSearchControlsDiv button {
            height: 23px;
            margin-left: 3px;
            margin-right: 3px;
            border: 1px solid #ffffff;
            color: #ffffff;
            background: transparent;
            border-radius: 2px;
            vertical-align: middle;
            line-height: 1.4285;
            font-weight: bold;
            font-family: "Core Sans C","Century Gothic","AppleGothic",sans-serif;
            letter-spacing: 0em;
        }

    .assuranceTopSection2 .filterOptionsDiv {
        width: 230px;
        display: table-cell;
        vertical-align: middle;
        height: 100%;
    }

        .assuranceTopSection2 .filterOptionsDiv .btn-group > :first-child.btn {
            width: 125px;
        }

        .assuranceTopSection2 .filterOptionsDiv .dropdown-menu {
            right: 0px;
            left: auto;
            border: 1px #9ba1a9 solid !important;
            border-radius: 3px;
            min-height: 0;
            max-height: 350px;
            overflow-y: auto;
            overflow-y: scroll;
        }

.assuranceTopSection3 {
    height: 50px;
    border: 1px solid #fff;
    border-radius: 8px !important;
    background-color: #fffcc3;
    border-bottom-width: 0px;
    color: #d84040;
    width: 100%;
    padding: 8px;
    padding-top: 2px;
    margin-top: -2px;
    margin-bottom: 5px;
}

    .assuranceTopSection3 .panel-heading {
        font-weight: bold;
        font-size: 13px;
        color: #d84040;
        background-color: #fffcc3;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 5px;
    }

.warningMessage .panel-heading {
    background-color: #ffebb6;
    color: #37474f;
}

.assuranceTopSection3 .panel {
    margin-bottom: -20px;
    border: none !important;
}

.quickLinksPanel > .panel {
    box-shadow: none;
}

.assuranceTopSectionItem {
    text-align: left;
    width: 100%;
    font-size: 11px;
    color: #d84040;
    background-color: #fffcc3;
    border: none;
    padding-top: 2px;
    padding-bottom: 2px;
}

.warningMessage {
    background-color: #ffebb6;
    color: #37474f;
}

#AssuranceHeaderReactContainer {
    max-width: 100%;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
}

.assuranceHeader {
    width: 100%;
    height: 36px;
}

.assuranceTestMenu {
    background-color: #f42a4d !important;
}


.assuranceMenu {
    background-color: #f4f4f4;
    border-radius: 0;
    width: 100%;
    border: 1px;
    /*border-image: linear-gradient(to bottom, #5ba1d1 0%, #2c6894 100%);*/
    height: 21px;
}

    .assuranceMenu .container {
        padding-right: 1px;
        padding-left: 100px;
    }

        .assuranceMenu .container .xxxtestModeLabel {
            vertical-align: middle;
            width: 80px;
            float: right;
        }


#AssuranceMenu nav.assuranceMenu .container ul.navbar-nav {
    margin-top: 2px;
}

.assuranceMenu .open {
    color: #0f0f59;
    background-color: #ffffff !important;
}

.navbar {
    min-height: 21px;
}

.navbar-nav > li > a {
    padding: 10px;
}

.assuranceMenu .navbar .navbar-default {
    margin: 0px auto;
}


    .assuranceMenu .navbar .navbar-default .container {
        background-color: inherit;
        min-height: inherit;
    }

.assuranceMenu .navbar-nav > li > a {
    font-size: 8pt;
    font-weight: 700;
    color: #0f0f59;
    max-height: inherit;
    line-height: 0px;
}

    .assuranceMenu .navbar-nav > li > a:hover,
    .assuranceMenu .navbar-nav > li > a:focus {
        color: #0f0f59;
        background-color: #e1e2e6 !important;
    }

.assuranceMenu .navbar-nav .open {
    color: #0f0f59;
    background-color: #e1e2e6;
    /*height: 33px;*/
}

.assuranceMenu .navbar-nav > .open > a,
.assuranceMenu .navbar-nav > .open > a:hover,
.assuranceMenu .navbar-nav > .open > a:focus {
    color: #0f0f59;
    background-color: #ffffff;
}

.assuranceMenu .dropdown-menu .divider {
    height: 1px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    background-color: #9ba1a9 !important;
}

.assuranceMenu .navbar-nav > li > .dropdown-menu {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    font-size: 12px;
    margin-top: 0;
}

.assuranceMenu .dropdown-menu > li > a:hover,
.assuranceMenu .dropdown-menu > li > a:focus {
    color: #002677;
    text-decoration: none;
    background-color:  #CDDDFE;
}

.assuranceMenu .dropdown-menu {
    font-size: 12px;
    font: #0f0f59 !important;
    background: #ffffff !important;
    border: 1px solid #c3c5cd;
}

.assuranceMenu .nav a:hover .caret {
    border-top-color: #000;
    border-bottom-color: #000;
    border-left: 4px solid #000;
    border-right: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
}

.assuranceMenu .nav .caret {
    border-top-color: #000;
    border-bottom-color: #000;
    border-left: 4px solid #000;
    border-right: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
}

.navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #c3c5cd;
    border-bottom-color: #c3c5cd;
}

.navbar-default .navbar-nav > .dropdown > a:hover .caret {
    border-top-color: #737d85;
    border-bottom-color: #737d85;
}

.navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #737d85;
    border-bottom-color: #737d85;
}

.assuranceMenu .open > .dropdown-menu > .open > .dropdown-menu {
    left: 100%;
    top: -5px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: block;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .active.btn-default, .open .btn-default.dropdown-toggle {
    background-color: #ffffff;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: #CDDDFE;
}

.btn-link:hover, btn-link:focus {
    color: #000000;
    text-decoration: none;
}

.btn-link {
    color: #000000;
}

#menu {
    list-style: none;
    width: 100%;
    margin: 0 auto;
    height: 45px;
    padding: 5px 30px;
}

    #menu li {
        float: left;
        display: block;
        position: relative;
        text-align: center;
        padding: 4px 10px;
        margin: 7px 30px 0 0;
        border: none;
    }

        #menu li:hover {
            border: 1px solid #c3c5cd;
            border-bottom: none;
            padding: 4px 9px;
            background: yellow;
            border-radius: 5px 5px 0 0;
            -moz-border-radius: 5px 5px 0 0;
            -webkit-border-radius: 5px 5px 0 0;
            z-index: 10;
        }

    #menu > li:hover:after {
        content: '';
        background: yellow;
        position: absolute;
        top: 100%;
        width: 100%;
        height: 4px;
        left: 0;
        margin-top: -4px;
        z-index: 2;
    }

    #menu li a {
        font-size: 14px;
        color: #37474f;
        display: block;
        outline: 0;
        text-decoration: none;
    }

.assurancePageFooterServer {
    display: flex;
    flex-flow: row wrap;
    width: 1050px;
    height: 110px;
    margin-top: 10px;
}

.pageFooterLogo {
    width: 189px;
    padding-top: 5px;
}

.pageFooterServer {
    padding-top: 40px;
    padding-left: 3px;
    width: 189px;
    text-align: left;
    font-size: 9pt;
    font-style: normal;
    color: #000000;
    cursor: default;
}

.pageFooterClient {
    padding-top: 15px;
    width: 285px;
    text-align: left;
    font-size: 9pt;
    font-style: normal;
    color: #0f0f59;
    cursor: default;
}

    .pageFooterClient .navbar .navbar-nav > li > a:hover, .pageFooterClient .navbar .navbar-nav > li > a:focus {
        color: #0f0f59;
    }

    .pageFooterClient .dropdown-menu {
        font-size: 11px;
        padding-left: 5px;
        padding-right: 5px;
        width: auto;
        white-space: nowrap;
        min-height: 0px;
        max-height: 350px;
        overflow-y: scroll;
        background: #efefef;
        border: 1px solid #9ba1a9;
        overflow-y: auto;
    }

    .pageFooterClient .navbar {
        background-color: transparent;
        border: 0px;
        width: 450px;
    }

    .pageFooterClient .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
        color: #0f0f59;
        background-color: transparent;
    }

    .pageFooterClient #singleClient {
        margin-top: 25px;
    }

    .pageFooterClient .dropdown {
        margin-top: -16px;
    }

    .pageFooterClient .nav > li > a {
        padding-top: 40px;
        color: #0f0f59;
    }

#FooterClient {
    width: 474px;
}

#footerClientItem {
    padding-top: 25px;
    float: left;
}

#footerClientList a {
    margin-top: 40px;
    padding: 0px;
    color: #0f0f59;
}

.footerPopover .navbar .dropdown-menu {
    padding: -550px;
}

.pageFooterPoweredBy {
    padding: 1px;
    width: 294px;
    text-align: right;
    font-size: 9pt;
    font-style: normal;
    color: #737d85;
    cursor: default;
}

    .pageFooterPoweredBy .brand-logo {
        height: 50px;
        margin-top: -10px;
    }

/* quicklinks panel */

.panel-group .panel {
    margin-bottom: 0px;
    overflow: hidden;
    border-radius: 0px;
}

    .panel-group .panel + .panel {
        margin-top: 5px;
    }

.panel-heading {
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding-top: 12px;
}

.panel-title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
}

.panel-link:hover {
    text-decoration: underline;
}

.panel-body {
    padding-left: 15px;
    padding-top: 5px;
}

/*.panel-collapse .collapse{
    display: none;
    font-size: 13px;
}*/

.qlPanel > .collapse {
    display: none;
    font-size: 13px;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    -o-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

.qlcaret {
    border: 5px solid transparent;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 11px;
    margin-right: 7px;
    cursor: pointer;
    text-decoration: underline;
}

    .qlcaret.right {
        border-left: 5px solid;
        color: #737d85;
    }

    .qlcaret.down {
        border-top: 5px solid;
        vertical-align: text-bottom;
        color: #000000;
    }



.qlPanel, .qlPanelExpanded {
    margin-bottom: -3px !important;
}

    .qlPanel .panel-heading {
        height: 40px;
        line-height: 10px;
        background: #F4F4F4;
    }

    .qlPanelExpanded .panel-heading {
        height: 40px;
        line-height: 10px;
        background: #F4F4F4;
    }

h1, h2, h3, h4, h5, h6 {
    font-family: "optum-sans-regular";
}

.qlPanel .panel-heading:hover, .qlPanelExpanded .panel-heading:hover {
    height: 40px;
    line-height: 10px;
    background: #F4F4F4;
}

.qlPanel .panel-heading .panel-title, .qlPanelExpanded .panel-heading .panel-title {
    line-height: 3em;
    margin-left: -10px;
}

.qlPanel .panel-body, .qlPanelExpanded .panel-body {
    height: auto;
    line-height: 10px;
    font-size: 11px;
    background: #F4F4F4;
    border-top: 0px;
}

    .qlPanel .panel-body a, .qlPanelExpanded .panel-body a {
        color: #7D7F81;
        margin-left: 25px;
        font-family: 'optum-sans-regular';
        font-weight: 600;
        font-size: 12px;
        text-decoration: none;
        letter-spacing: 0.4px;
    }

.qlPanel li, .qlPanelExpanded li {
    list-style-type: none;
    margin: 0 0 12px 0;
}

.quickLinksHeader {
    height: 41px;
    width: 50%;
    color: #0f0f59;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    line-height: 41px;
    margin-left: -15px;
}

.quickLinksPanel {
    font-family: "optum-sans-regular";
    width: 185px;
    height: 100%;
}

.quickLinksPanelLoader {
    width: 173px;
    height: 724px;
    border: 1px solid #e1e2e6;
}

.blueFont {
    color: #0f0f59;
}

.componentTop {
    height: 40px;
    max-height: 40px;
    width: 100%;
    font-size: 11px;
    font-family: 'optum-sans-regular' !important;
}

.btn-default,
.btn-default:hover,
.dropdown-menu {
    background-color: #ffffff;
    border: none;
    color: #0f0f59;
    font-size: 9px;
    font-weight: bold;
}

.componentTop .btn {
    padding-top: 2px;
    padding-bottom: 2px;
}

.smallComponent {
    height: 190px;
}

    .smallComponent .jqplot-title {
        font-family: "optum-sans-regular";
        color: #4B4D4F;
        font-size: 13px;
        font-weight: 900;
        margin-top: 5px;
        position: relative !important;
        left: -40px !important;
        text-align: left !important;
    }

.legendClass {
    width: 390px;
    position: relative;
    left: -60px;
    font-family: 'optum-sans-regular';
}

.legendClass,
.jqplot-data-label {
    margin-left: 6px;
    margin-right: 6px;
    color: #000000;
    font-size: 12px;
}

::-webkit-scrollbar-button {
    background-color: #7c2929;
}

.myClaims {
    scrollbar-face-color: #e5f8ff;
}

    .legendClass table,
    .myClaims table {
        width: 100%;
    }

.RAPMyClaims table,
.myClaims {
    color: #000000;
    font-size: 11px;
}

.legendClass tr {
    height: 20px;
}

.myClaims tr {
    height: 25px;
    cursor: pointer;
}


.legendClass thead {
    border: 1px solid #c3c5cd;
}

.legendClass table > tbody > tr:nth-of-type(even),
.myClaims tbody > tr:nth-of-type(even) {
    background-color: #f4f4f4;
}

.myClaimsHeader {
    font-weight: bold;
    width: 100%;
    max-height: 98%;
}

    .myClaimsHeader input {
        height: 30px;
        border: 1px solid #7D7F81 !important;
        box-shadow: rgba(155, 161, 169, 0.25) 0px 2px 2px 0px inset !important;
        text-transform: capitalize !important;
        border-bottom: none;
    }

        .myClaimsHeader input:-ms-input-placeholder {
            padding-left: 3px;
            color: #737d85;
            font-size: 8px;
            font-weight: bold;
        }

.myClaimsHeaderInput ::placeholder {
    padding-left: 3px;
    color: #737d85;
    font-size: 8px;
    font-weight: bold;
}

.myClaimsHeaderInput input::-webkit-input-placeholder {
    padding-left: 3px;
    color: #737d85;
    font-size: 8px;
    font-weight: bold;
}


.smallComponent .jqplot-table-legend-swatch-outline,
.smallComponent .jqplot-table-legend {
    border: 0 solid #c3c5cd;
}

    .smallComponent .jqplot-table-legend a {
        color: #000000;
        font-size: 9px;
    }

.smallComponent table.jqplot-cursor-legend, table.jqplot-table-legend {
    font-size: 9px;
}

.flex-componentcontainer {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: row wrap;
}
/*
.componentLoading {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}
*/
.noDataColor {
    background-color: #fff;
}

/*
.componentLoadingTop {
    display: flex;
    flex: 1 1 auto;
}

.componentLoadingContent {
    display: flex;
    flex: 0 1 auto;
}

.componentLoadingBottom {
    display: flex;
    flex: 1 1 auto;
}
*/

.componentError {
    background-color: #FDF6E3;
    width: 100%;
    height: 100%;
}

.componentErrorContent {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

span > label.disabled {
    opacity: 0.9;
}

.componentErrorText {
    font-size: 13px;
    color: #d84040;
    font-weight: bold;
}

/*
.componentMessageText {
    font-size: 13px; 
}
*/

.componentErrorIcon {
    width: 100%;
}

    .componentErrorIcon img {
        display: block;
        margin: 0 auto;
    }

.componentHeader {
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 11px;
}

.RAPClaimCreationState {
    width: 400px !important;
    border-radius: 8px;
    box-shadow: rgba(17, 80, 244, 0.1) 0px 1px 2px, rgba(131, 144, 175, 0.3) 0px 2px 12px;
}

.RAPTitle {
    position: relative;
    border: 1px solid #e1e2e6;
    display: none;
}

#RAPTopSection{
    width: 100% !important;
}

.RAPAdSpace {
    border-radius: 8px;
    width: 270px !important;
    height: 362px !important;
    box-shadow: rgba(17, 80, 244, 0.1) 0px 1px 2px, rgba(131, 144, 175, 0.3) 0px 2px 12px;
}

.RAPAdSpaceLoader {
    width: 282px;
    height: 310px;
    border: 1px solid #e1e2e6;
}

.RAPAdSpace .carousel,
.smallOuterComponent {
    width: 282px;
    height: 310px;
}

.RAPAdSpace .carousel-inner .carousel-item {
    height: 267px;
}

.RAPAdSpaceItem {
    display: inline-block;
    position: relative;
    top: 40%;
    left: 45%;
    transform: translate(-50%, -50%);
}

.RAPAdSpaceItemHide {
    display: none;
}

.RAPAdSpace .carousel-control.left, .carousel-control.right {
    background-image: none !important;
    filter: none !important;
}

.RAPAdSpace .carousel-indicators {
    bottom: 15px;
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: auto;
    right: 0px;
    left: auto;
    width: auto;
}

    .RAPAdSpace .carousel-indicators li {
        width: 15px;
        height: 15px;
        border: 2px solid #efefef;
        background-color: #efefef;
    }

    .RAPAdSpace .carousel-indicators .active {
        margin: 1px;
        width: 15px;
        height: 15px;
        border: 2px solid #e47979;
        background-color: #efefef;
    }

.blueButton,
.helpButton {
    color: #ffffff;
    border-radius: 2px;
    border: 1px solid;
    border-color: #ffffff;
    font-size: 11px;
    background-color: transparent;
    font-weight: bold;
}

    blueButton:hover,
    .helpButton:hover {
        border-color: #caf0ff;
        color: #caf0ff;
    }

    .blueButton:active,
    .helpButton:active {
        border-color: #4cc6ff;
        color: #4cc6ff;
    }
/*
.calendarButton {
	background-color: transparent;
    background-image: url('images/CalendarIcon.png');
    background-repeat: no-repeat;
    border: none;
    width: 32px;
    height: 30px;
}

.calendarButton:hover {
    background-image: url('images/CalendarIconhover.png');
}
*/
/*rules applied to UnreleasedClaimAgeChart*/
.unreleasedClaimAgeContainer {
    margin-top: 5px;
    margin-left: 5px;
    height: 311px;
    width: 400px;
}

.unreleasedClaimAgeChart {
    height: 265px;
    left: 7px;
    width: 100%;
}


.RAPUnreleasedClaimAge {
    width: 450px !important;
}

    .RAPUnreleasedClaimAge > .componentTop {
        border-left: none;
    }

.header {
    font-size: 14px;
}

.RAPUnreleasedClaimAgeLoader {
    height: 312px;
    width: 550px;
    margin-top: 5px;
    margin-left: 5px;
    border: 1px solid #e1e2e6;
}

.RAPClaimCreationStateLoader {
    width: 281px;
    height: 312px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid #e1e2e6;
}

.msgListLegacyPage tr:nth-child(even) {
    background-color: green;
}

/* legacy landing page messages 
.msgListLegacyPage {
    border-collapse: separate;
    border-spacing: 0px 0px 0px 0px;
}
*/
/*
.msgListRows > tbody {
    display: block;
}
*/

.armMessages {
    table-layout: fixed !important;
    height: 335px;
}

.msgListLegacySubject {
    font-size: 12.5px;
}

.msgListLegacyRow {
    /*
    height: 17px;
    line-height: 17px !important;
    */
    /*background-color: black;*/ /*rgb(195, 197, 205);  same as: '#c3c5cd'; */
    /*background-color: rgb(225, 226, 230); same as #e1e2e6 */
}

/* end-legacy landing page messages */
.jqplot-axis {
    color: #000000;
    font-size: 10px;
}

.jqplot-xaxis {
    color: #000000;
    font-size: 10px;
}

.jqplot-x2axis {
    color: #000000;
    font-size: 10px;
}

.jqplot-yaxis {
    color: #000000;
    font-size: 10px;
}

.jqplot-axis-tick {
    color: #800080;
}

.jqplot-target {
    margin-top: 0px !important;
    cursor: pointer !important;
    color: #000000 !important;
}

.jqplot-y2axis-tick {
    margin-left: 3px;
}

.jqplot-yaxis-tick {
    margin-right: 3px;
    text-align: center;
}

.unreleasedClaimAgeChart > .jqplot-highlighter-tooltip {
    border: 1px solid #e1e2e6;
    border-radius: 5px;
    font-size: 0.75em;
    font-size: 12px;
    white-space: nowrap;
    background: #ffffff;
    padding: 1px;
}

.claimAgeLegend {
    font-size: 10px;
    display: inline-block;
    padding-left: 110px;
    padding-top: 5px;
}

.lgd {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    display: inline-block;
}

.blue {
    background: #1E82CB;
}

.green {
    background: #63E0C9;
}

.lgdText {
    width: 100px;
    display: inline-block;
    padding-left: 3px;
}

.claimAgePopup {
    background-color: #FFFFFF;
    font-family: 'optum-sans-regular' !important;
    font-size: 12px;
    margin: 5px;
}

.ComponentHeading {
    font-family: 'optum-sans-regular' !important;
    margin: 10px;
    font-size: 13px;
}

    .ComponentHeading span {
        font-family: 'optum-sans-regular' !important;
        font-size: 12px;
    }

.inboxTxt3 {
    width: 168px;
}

.countTxt3 {
    width: 82px;
}

.valueTxt3 {
    width: 120px;
}

.oldestTxt3 {
    width: 82px;
}

.myClaimsTd {
    padding-left: 5px;
}

.inboxTD3 {
    width: 40%;
}

.countTD3 {
    width: 16%;
    text-align: right;
    padding-right: 8%;
}

.valueTD3 {
    width: 26%;
    text-align: right;
    padding-right: 2%;
}

.oldestTD3 {
    width: 18%;
    text-align: right;
    padding-right: 2%;
}


.inboxTxt1 {
    width: 300px;
}

.countTxt1 {
    width: 100px;
}

.valueTxt1 {
    width: 200px;
}

.oldestTxt1 {
    width: 100px;
}

.inboxTD1 {
    width: 40%;
}

.countTD1 {
    width: 10%;
    text-align: right;
    padding-right: 7%;
}

.valueTD1 {
    width: 30%;
    text-align: right;
    padding-right: 7%;
}

.oldestTD1 {
    width: 20%;
    text-align: right;
    padding-right: 13%;
}

.userProductivityLegend {
    font-weight: bold;
    font-size: 10px;
    color: #000000;
    margin-left: 45px;
    margin-top: 7px;
}

.RAPWorkProductivity {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(17, 80, 244, 0.1) 0px 1px 2px, rgba(131, 144, 175, 0.3) 0px 2px 12px;
    padding: 5px;
    width: 575px !important;
}

.RAPWorkProductivityLoader {
    height: 310px;
    width: 530px;
    margin-top: 5px;
    margin-left: 5px;
    border: 1px solid #e1e2e6;
}

.RAPMyClaims > .componentHeader {
    width: 100%;
    height: 40px;
    background-color: #0f0f59;
    color: #ffffff;
    font-size: 11px;
}

.RAPWorkProductivity > .componentHeader {
    border-right: none !important;
}

.RAPWorkProductivity > .componentTop {
    border-bottom: 1px solid red;
}

.RAPWorkProductivity > .componentHeader > .componentTop {
    height: 35px;
    font-size: 12px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
}

.myClaimsForm {
    visibility: hidden;
}

.range_inputs {
    width: 54px;
}

.btn-sm {
    margin-bottom: 5px;
    width: 50px;
}

.panel-default > .panel-heading + .panel-collapse .panel-body,
.panel-group .panel-heading + .panel-collapse .panel-body,
.panel-default > .panel-heading,
.panel-default {
    border: 1px solid transparent !important;
}

.daterangepicker th {
    border-bottom-style: none !important;
}

.daterangepicker td {
    border: none !important;
    padding: 6px;
}

.btn-success {
    background-color: #002677 !important;
    border: none;
}

#branding {
    height: 36px;
    position: absolute;
    left: 0;
    margin: 0 auto;
    padding: 0;
}

    #branding img {
        height: 36px;
        width: 300px;
    }

.footerCopyright {
    width: 100%;
    margin-top: -10px;
}

    .footerCopyright div {
        float: right;
        max-width: 290px;
        font-size: 9pt;
        margin-top: -35px;
    }

/* adspace Component styles */
.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

    .carousel-inner > .item {
        position: relative;
        display: none;
        -webkit-transition: 0.6s ease-in-out left;
        -o-transition: 0.6s ease-in-out left;
        transition: 0.6s ease-in-out left;
    }

        .carousel-inner > .item > img,
        .carousel-inner > .item > a > img {
            line-height: 1;
        }

@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-inner > .item {
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        -o-transition: -o-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out, -o-transform 0.6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px;
    }

        .carousel-inner > .item.next,
        .carousel-inner > .item.active.right {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            left: 0;
        }

        .carousel-inner > .item.prev,
        .carousel-inner > .item.active.left {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
            left: 0;
        }

            .carousel-inner > .item.next.left,
            .carousel-inner > .item.prev.right,
            .carousel-inner > .item.active {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                left: 0;
            }
}

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
    display: block;
}

.carousel-inner > .active {
    left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel-inner > .next {
    left: 100%;
}

.carousel-inner > .prev {
    left: -100%;
}

    .carousel-inner > .next.left,
    .carousel-inner > .prev.right {
        left: 0;
    }

.carousel-inner > .active.left {
    left: -100%;
}

.carousel-inner > .active.right {
    left: 100%;
}

.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0);
    filter: alpha(opacity=50);
    opacity: 0.5;
}

    .carousel-control.left {
        background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
        background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
        background-repeat: repeat-x;
    }

    .carousel-control.right {
        right: 0;
        left: auto;
        background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
        background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
        background-repeat: repeat-x;
    }

    .carousel-control:hover,
    .carousel-control:focus {
        color: #fff;
        text-decoration: none;
        outline: 0;
        filter: alpha(opacity=90);
        opacity: 0.9;
    }

    .carousel-control .icon-prev,
    .carousel-control .icon-next,
    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right {
        position: absolute;
        top: 50%;
        z-index: 5;
        display: inline-block;
        margin-top: -10px;
    }

    .carousel-control .icon-prev,
    .carousel-control .glyphicon-chevron-left {
        left: 50%;
        margin-left: -10px;
    }

    .carousel-control .icon-next,
    .carousel-control .glyphicon-chevron-right {
        right: 50%;
        margin-right: -10px;
    }

    .carousel-control .icon-prev,
    .carousel-control .icon-next {
        width: 20px;
        height: 20px;
        font-family: serif;
        line-height: 1;
    }

        .carousel-control .icon-prev:before {
            content: "\2039";
        }

        .carousel-control .icon-next:before {
            content: "\203A";
        }

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
}

    .carousel-indicators li {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 1px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #000 \9;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #fff;
        border-radius: 10px;
    }

    .carousel-indicators .active {
        width: 12px;
        height: 12px;
        margin: 0;
        background-color: #fff;
    }

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

    .carousel-caption .btn {
        text-shadow: none;
    }

@media screen and (min-width: 768px) {
    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-prev,
    .carousel-control .icon-next {
        width: 30px;
        height: 30px;
        margin-top: -10px;
        font-size: 30px;
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .icon-prev {
        margin-left: -10px;
    }

    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next {
        margin-right: -10px;
    }

    .carousel-caption {
        right: 20%;
        left: 20%;
        padding-bottom: 30px;
    }

    .carousel-indicators {
        bottom: 20px;
    }
}

.adDot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

    .active, .adDot:hover {
        background-color: #fff;
    }

.daterangepicker {
    font-family: "optum-sans", 'optum-sans-regular';
    font-size: 12px;
}

.daterangepicker td.active {
    background-color: #001D5B !important;
}

.daterangepicker td.in-range{
    background-color: #E9F1FF;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: #CDDDFE;
    border-radius: 4px;
}

.daterangepicker td.end-date:hover, .daterangepicker td.start-date:hover{
    border-radius: 4px !important;
}

.daterangepicker_input > input {
    font-size: 15px;
}

.daterangepicker.dropdown-menu {
    border: 0.5px solid #000;
}

.applyBtn.btn.btn-sm.btn-success {
    color: #fff;
    font-size: 14px;
    width: 57px;
    padding: 5px;
    border-radius: 14px;
    cursor: pointer;
}

.cancelBtn.btn.btn-sm.btn-default {
    font-size: 14px;
}

.assuranceTopSection3 > .panel > .panel-body {
    padding: 0px;
    border-radius: 8px;
    margin-left: 2px;
    margin-right: 2px;
}

.RAPHelp-button {
    padding: 0px !important;
}
