.tooltip-supplementeditcriteria {
    position: absolute;
    z-index: 1070;
    display: block;
    line-height: 1.42857143;
    line-break: auto;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 12px;
    filter: alpha(opacity=0);
    opacity: 0;
    font-family: "Core Sans C", "Century Gothic", AppleGothic, sans-serif;
    font-size: 10pt;
}

    .tooltip.in {
        filter: alpha(opacity=90);
        opacity: 0.9;
    }

    .tooltip.top {
        padding: 5px 0;
        margin-top: -3px;
    }

    .tooltip.right {
        padding: 0 5px;
        margin-left: 3px;
    }

    .tooltip.bottom {
        padding: 5px 0;
        margin-top: 3px;
    }

    .tooltip.left {
        padding: 0 5px;
        margin-left: -3px;
    }

    .tooltip.top .tooltip-arrow {
        bottom: 0;
        left: 10%;
        margin-left: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
    }

    .tooltip.top-left .tooltip-arrow {
        right: 5px;
        bottom: 0;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
    }

    .tooltip.top-right .tooltip-arrow {
        bottom: 0;
        left: 5px;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
    }

    .tooltip.right .tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
        border-right-color: #000;
    }

    .tooltip.left .tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -5px;
        border-width: 5px 0 5px 5px;
        border-left-color: #000;
    }

    .tooltip.bottom .tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
    }

    .tooltip.bottom-left .tooltip-arrow {
        top: 0;
        right: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
    }

    .tooltip.bottom-right .tooltip-arrow {
        top: 0;
        left: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
    }

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

body .tooltip-supplementeditcriteria .tooltip-inner {
    max-width: 500px !important;
    padding: 3px 8px !important;
    color: #000 !important;
    text-align: left !important;
    background-color: #fff !important;
    border-radius: 4px !important;
    border: 1px solid black;
}

body .tooltip-supplementeditcriteria {
    opacity: 1 !important;
    
}