
/*
input[disabled] {
    background-color: #C3C5CD;
}
*/
div[id^="selectManualElgOptionsPayers"] button[class^="ButtonRow-"].focused {
    box-shadow: none !important;
    border-left: none !important;
    border-right: none !important;
}

.dropdown{
    .bBTdyP {
        font-family: optum-sans-regular !important;
        font-size: 11px !important;
        font-weight: 600 !important;
        line-height: 1.4em !important;
    }
}

div.EligibilityLabelDisabled {
    color: #C3C5CD;
}

.EligibilityRank {
    vertical-align: super;
    font-size: 8pt;
    font-weight: normal;
    color: #000000;
}

.EligibilityRankDisabled {
    vertical-align: super;
    font-size: 8pt;
    font-weight: normal;
    color: #C3C5CD;
}

table.EligibilityResponse {
    border-collapse: separate;
    border-spacing: 1px;
    font-family: 'optum-sans-regular';
    font-size: 11px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: 0.5px;
    width:auto;
}

td.Field {
    padding: 5px;
    margin: 0;
}

    td.Field.Label {
        text-align: right;
    }

    td.Field.small {
        width: 100px;
    }

    td.Field.x-small {
        width: 70px;
    }

    td.Field.medium {
        width: 120px;
    }

    td.Field.large {
        width: 190px;
    }

    td.Field.x-large {
        width: 300px;
    }
