
/*
input[disabled] {
    background-color: #C3C5CD;
}
*/

div[id^="selectViewManualElgOptionsPayers"] button[class^="ButtonRow-"].focused {
    box-shadow: none !important;
    border-left: none !important;
    border-right: none !important;
}

table#searchResults, .EligibilityResponseGrid {
    border-collapse: separate;
    border-spacing: 1px;
    /* font-family: 'optum-sans-regular';
    font-size: 11px;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 0.5px;
    width: auto; */
}

    table#searchResults thead {
        border-top: none;
        border-left: none;
        border-right: none;
        background: red;
        overflow: auto;
    }

    table#searchResults thead tr {
    }

    table#searchResults thead tr th {
        white-space: nowrap;
    }

    table#searchResults tbody tr td:last-child { /* this is to work around a UICL Grid bug. */
        background-color: crimson; /* if this is displayed, something did not work */
        display: none;
    }

    .searchBtn, .clearBtn {
        margin-right: 10px !important;
    }
   