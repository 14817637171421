/* Install Fonts */
@font-face {
    font-family: "optum-sans-regular";
    src: url("../fonts/OptumFonts/optum-sans-regular.woff2");
    font-weight: 400;
}

@font-face {
    font-family: 'optum-sans-bold';
    src: url("../fonts/OptumFonts/optum-sans-bold.woff2");
    font-style: normal;
}

@font-face {
    font-family: "Optum Sans";
    src: url("../fonts/OptumFonts/optum-sans-regular.woff2");
    font-weight: 400;
}

@font-face {
    font-family: 'Optum Sans Bold';
    src: url("../fonts/OptumFonts/optum-sans-bold.woff2");
    font-style: normal;
}