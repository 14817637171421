div[id^="triggerevent-dropdown-select-menu"] button[class^="ButtonRow-"].focused {
    box-shadow: none !important;
    border-left: none !important;
    border-right: none !important;
}
div[id^="autolist_time_hr-select-menu"] div[class^="selectDropdown__menu"]{
    width:80px !important;
}
div[id^="autolist_time_min-select-menu"] div[class^="selectDropdown__menu"]{
    width:80px !important;
}
div[id^="autolist_time_hr-select-menu"] button[class^="ButtonRow-"] {
    padding:8px 18px !important;
}
div[id^="autolist_time_min-select-menu"] button[class^="ButtonRow-"] {
    padding:8px 18px !important;
}
span[id^="autolist-tab-monthly-tooltip"] label[class^="Tabstyles__TabLabelWrapper"]{
    max-width: 160px !important;
}
div[class^="TabsWrapper__TabsChildrenWrapper"]{
    padding-right:0px !important;
}
div[class^="TabsWrapper"] span[class="TabsWrapper__TabsSwitcherWrapper"]{
    margin-left:10px !important;
}